<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'BadgeList',
              }"
              >徽章管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>徽章資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-2 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">徽章明細</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group :disabled="readonly">
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="徽章名稱"
              >
                <b-form-input v-model="badge.name"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="徽章描述"
              >
                <b-form-textarea v-model="badge.description"></b-form-textarea>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="排序"
              >
                <b-form-input type="number" v-model="badge.sort"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="圖片"
              >
                <img class="s-badge-image" :src="badge.image_url" />

                <!-- 發現手機板chrome 的click 似乎與draggable 處理event 衝突, 先簡單用touchstart 解掉 -->
                <ImageSelector
                  v-if="!readonly"
                  @input="handleImagesChange($event)"
                  :aspectRatio="1"
                >
                  <template #trigger="{ openFileSelector }">
                    <button
                      class="btn s-trigger-btn"
                      :disabled="!canClick"
                      @click="openFileSelector"
                    >
                      <template v-if="canClick">
                        <div class="add-image-icon">＋</div>
                        <div>
                          {{ badge.image_url ? "更換圖片" : "新增圖片" }}
                        </div>
                      </template>

                      <template v-else> 上傳中... </template>
                    </button>
                  </template>
                </ImageSelector>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="是否啟用"
              >
                <b-form-checkbox
                  type="boolean"
                  v-model="badge.is_enabled"
                  switch
                ></b-form-checkbox>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          v-if="!readonly"
          style="margin-top: 80px"
        >
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button variant="success" @click="handleSubmit">
            <template v-if="isEdit">確認編輯</template>

            <template v-if="isCreate">確認新增</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import badgeApi from "../../../apis/badge";
import { zh } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapState } from "vuex";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import { format } from "date-fns";
import ImageSelector from "@/components/ImageSelector";

export default {
  components: { ImageSelector },
  data() {
    return {
      zh,
      showLoading: false,
      badge: {
        name: "",
        description: "",
        is_enabled: true,
        sort: 0,
        image_url: "",
      },
      image: null,
      canClick: true,
      canUpload: true,
    };
  },
  watch: {
    async image(val) {
      if (val) {
        if (val.startsWith("data:image/jpeg;base64")) {
          await this.handleImagesChange(val);
        }
      }
    },
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('route', ['routeQuery']),
    readonly() {
      return this.$route.name === "BadgeDetail";
    },
    isEdit() {
      return this.$route.name === "BadgeEdit";
    },
    isCreate() {
      return this.$route.name === "BadgeCreate";
    },
  },
  mounted() {
    if (!this.isCreate) {
      this.fetchBadge();
    }
  },
  methods: {
    format,
    formatToDateObject(datetime) {
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      };
    },
    async fetchBadge() {
      this.showLoading = true;

      try {
        const { data } = await badgeApi.getBadge(this.$route.params.badgeId);
        this.badge = data.data;
      } catch (error) {
        console.error(error);
      }
      this.showLoading = false;
    },
    handleCancel() {
      this.$router.push({ name: "BadgeList", query: this.routeQuery });
    },
    async handleSubmit() {
      this.showLoading = true;

      if (this.isEdit) {
        await this.goUpdate();
      }
      if (this.isCreate) {
        await this.goCreate();
      }
      this.showLoading = false;
    },
    async goUpdate() {
      try {
        await badgeApi.updateBadge(this.badge.id, this.badge);
        this.$swal
          .fire({
            type: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "BadgeList", query: this.routeQuery });
          });
      } catch (error) {
        const { status, data } = error.response;

        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
    },
    async goCreate() {
      try {
        await badgeApi.createBadge(this.badge);
        this.$swal
          .fire({
            type: "success",
            text: "徽章建立成功",
          })
          .then(() => {
            const params = { name: "BadgeList", query: this.routeQuery };
            this.$router.push(params);
          });
      } catch (error) {
        const { status, data } = error.response;

        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
    },
    async handleImagesChange(imageBase64) {
      try {
        this.canClick = false;
        const response = await badgeApi.uploadImageByBase64(imageBase64);
        this.badge.image_url = response.data.image_url;
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      } finally {
        this.canClick = true;
      }
    },
  },
};
</script>

<style scoped>
.s-badge-image {
  width: 25%;
}
</style>
